.table-keyboard-shortcuts {
  position: relative;
  padding: 2em 0 0 0;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 0 1em 0;
    color: #aaa;
    font-size: smaller;
    font-weight: 500; }

  &::after {
    clear: both;
    content: " ";
    display: table; }

  .table {
    margin-bottom: 0;

    td, .table th {
      padding: .25rem;
      width: 50%; }

    .shortcut-key {
      width: 30%; } } }

.table-keyboard-shortcuts.reproduction::before {
  content: '再生'; }

.table-keyboard-shortcuts.screen::before {
  content: '画面'; }

.table-keyboard-shortcuts.note::before {
  content: 'メモ';
  padding: 1em 0 1em 0; }

.table-keyboard-shortcuts.note {
  padding: 3em 0 0 0; }

.modal .modal-dialog[data-theme="dark"] .modal-content .table-keyboard-shortcuts .table {
  color: #fff; }

@media (min-width: 576px) {
  .modal-table-keyboard-shortcuts {
    max-width: 670px; } }
