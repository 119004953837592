.txt:hover {
    text-decoration: underline; }
.buttonIndexer {
    background-color: #3386cc !important; }
.message-success {
    color: #34ce57; }
.loading {
    height: 35px;
    width: 35px;
    margin-bottom: 3px;
    margin-left: -9px; }
.message-fail {
    color: red; }
.txt {
    cursor: pointer; }
.radioButtonEng {
    margin-left: 15px; }
.border-ul {
    border-bottom: 1px solid #dee2e6 !important; }
.none-bold {
    b {
        font-weight: normal; } }
pre {
    color: unset; }

.tab-comment {
    .column {
        border-bottom: 1px solid #ced4da;
        padding-bottom: 10px; } }

.memo-item {
    .row {
        margin-top: 10px;
        margin-left: 5px;
        width: 100%; }

    .media {
        width: 100%;

        .media-image > img {
            width: 100%;
            max-width: 100px; } } }

#flatListForExtend {
    .column {
        border-bottom: 1px solid #ced4da;
        border-left: 5px solid transparent;

        &.new {
            border-left-color: #237AC2; }

        .row {
            padding: 10px;
            margin-top: 0 !important; } } }

.itemPlayList, .related-content-div {
    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0px;
        overflow-wrap: break-word;
        background-color: rgb(255, 255, 255);
        background-clip: border-box;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.086);
        border-image: initial;
        border-radius: 0.4375rem; }

    .product-card {
        padding-bottom: 0.875rem;
        border: none;
        transition: all 0.15s ease-in-out 0s; }

    .image-time-watched {
        position: relative;
        border-width: 0px !important; }

    .card-img, .card-img-top {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px; }

    .img-wrapper {
        position: relative !important;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(218, 225, 231);
        border-image: initial;
        background: rgb(0, 0, 0); }

    .product-card .card-body {
        position: relative;
        background-color: rgb(255, 255, 255);
        z-index: 2; }

    .card-body {
        min-height: 1px;
        flex: 1 1 auto;
        padding: 1.25rem; }

    .product-title {
        font-size: 0.875rem;
        &> a {
            color: rgb(55, 63, 80);
            transition: color 0.25s ease-in-out 0s;
            text-decoration: none !important; } }

    img, figure {
        max-width: 100%;
        height: auto;
        vertical-align: middle; }

    .program-duration {
        background-color: rgba(43, 52, 69, 0.7);
        position: absolute;
        color: rgb(255, 241, 231);
        right: 0px;
        bottom: 0px;
        width: 50px;
        text-align: center;
        font-size: 14px; }

    .theme-text {
        line-height: 0.75rem;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        font-size: 0.65rem !important;
        overflow: hidden;
        color: rgb(55, 63, 80);
        font-weight: normal; } }

.app-body[data-theme=dark] {
    .product-title {
        background-color: unset;
        color: #fff;

        &> a {
            color: #fff; } }

    .card {
        background-color: unset; }
    .product-card .card-body {
        background-color: unset; } }

.video-description .favorite-slide {
    position: absolute;
    right: -2px;
    top: -7px;
    height: 49px;
    width: 33px;
    z-index: 499; }

.video-description .lec-image {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    object-fit: cover; }

.video-description {
    .collapse-lec-profile.hide {
        height: 1rem;
        overflow: hidden; }

    .collapse-lec-profile.show {
        height: auto;
        overflow: visible; }

    .iframe-import {
        height: 800px; } }

@media (max-width: 1264px) {
    .video-description .iframe-import {
        height: 1600px; } }
