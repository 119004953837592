$DARK_BG_COLOR: #222;

.app-body[data-theme="dark"] {
  border-radius: unset;
  header {
    background-color: $DARK_BG_COLOR; }

  .nav-item a {
    color: #fff; }

  .dropdown .dropdown-menu {
    background-color: #212F3D;
    button, a {
      color: #fff;
      &:hover {
        background-color: $DARK_BG_COLOR; } } }

  .player .progress {
 }    //visibility: hidden

  .learning-container {
    background-color: #222 !important; }

  .video-description {
    .nav-tabs .nav-item .nav-link {
      &.active {
        background-color: #212F3D;
        color: #fff;
        border-bottom-color: #fff; }
      &:hover {
        color: #fff;
        border-bottom: 3px solid #ffffff; } }
    .tab-content {
      textarea, input {
        color: #fff;
        background-color: #212F3D; } } }

  .video-timeline {
    .list-group-item {
      background-color: unset;
      color: #fff; }

    .list-group-item-action {
      &.gray-background, &:active {
        background-color: #212F3D; }
      &:hover {
        background-color: #2d4053; } } } }

.modal .modal-dialog[data-theme="dark"] {
  .modal-content {
    background-color: $DARK_BG_COLOR;
    color: #fff;
    border: 1px solid #fff; } }
