.bi.bi-bookmark-plus {
  color: #212529; }

.app-body[data-theme="dark"] {
  .bi.bi-bookmark-plus {
    color: #fff; } }

.dropdown-playlist {
  .bi.bi-heart-fill {
    color: #FE7879; } }

.modal-add-item-to-playlist {
  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.25rem + 2px);
    padding: .625rem 1rem;
    font-size: .9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4b566b;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dae1e7;
    border-radius: .3125rem;
    box-shadow: 0 0 0 0 transparent;
    transition: border-color 0.2s ease-in-out,box-shadow 0.2s ease-in-out; } }
