#root {
  position: relative;

  #loading-panel-screen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    min-height: 100vh;

    .loading-screen {
      position: absolute;
      height: 100vh;

      .loading-icon {
        width: 100px;
        height: 100px;
        background-size: cover;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } } } }
