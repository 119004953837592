.btn-swap {
    cursor: pointer; }
.comment-extend__button-toggle {
    z-index: 1;
    position: fixed;
    bottom: 15px;
    right: 15px;
    border-radius: 9999px;
    width: 60px;
    height: 60px;
    background-color: rgba(0,0,0,0.6);

    &:hover {
        background-color: #0056B3; }

    &[data-open="true"] {
        right: calc(100vw * (3/12) + 10px); }

    .MuiIconButton-label {
        color: #fff; }
    .MuiIconButton-root {
        font-size: 2rem;
        padding: 18px; } }

body.modal-open .comment-extend__button-toggle {
    margin-right: 17px; }

.slider-download-not-slide {
    button {
        margin-top: -7px; } }

.bi.bi-circle-half, .bi.bi-arrow-left-right, .bi.bi-easel3, .bi.bi-easel3-fill {
    color: #212529; }

.app-body {
    min-height: 100vh; }

.app-body[data-theme="dark"] {
    .bi.bi-circle-half, .bi.bi-arrow-left-right, .bi.bi-easel3, .bi.bi-easel3-fill {
        color: #fff; } }

.MuiBreadcrumbs-ol .MuiBreadcrumbs-li, .MuiBreadcrumbs-ol .MuiBreadcrumbs-li .MuiTypography-body1,
.MuiBreadcrumbs-ol .MuiBreadcrumbs-li .MuiTypography-colorPrimary {
    font-family: "Rubik",sans-serif;
    font-size: .8125rem;
    font-weight: normal;
    color: #7d879c;
    text-decoration: none; }

.MuiBreadcrumbs-ol .MuiBreadcrumbs-li a:hover {
    color: rgb(51, 134, 204) !important; }

.MuiBreadcrumbs-li > a > i {
    margin-top: -0.175rem;
    margin-right: 0.325rem;
    font-size: 1.05em;
    vertical-align: middle; }

.MuiBreadcrumbs-separator .czi-arrow-right {
    font-size: .9em;
    display: inline-block;
    color: #a6acb7; }

.wapper-btn-player {
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.7);
    width: 100%;
    height: 100%;

    .wapper-btn-player-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: max-content; }

    .btn {
        width: 100%;
        max-width: 235px;
        font-size: 14px;
        color: #FFFFFF;
        border-radius: 5px;
        padding: 8px 10px;
        box-sizing: border-box; }

    .btn-login {
        background-color: #3386cc;
        &:hover {
            background-color: #FFFFFF;
            color: #3386cc;
            border: 1px solid #3386cc; } }

    .btn-register {
        background-color: #FF8C00;
        margin-top: 10px;
        &:hover {
            background-color: #FFFFFF;
            color: #FF8C00;
            border: 1px solid #FF8C00; } } }
