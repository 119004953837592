.learning-container {
  .learning-content {
    padding: 10px; }

  .learning-actions {
    display: flex;
    flex-direction: row;
    button {
      margin-left: 20px;
      span {
        white-space: nowrap; } } } }

@media (max-width: 480px) {
  .learning-actions {
    flex-direction: column !important;
    button {
      padding: 2px !important;
      &:last-child {
        margin-top: 5px; } } } }

.learning-course-list {
  ul {
    list-style-type: none;
    padding: 0 20px;

    li {
      padding: 2px 0; } } }
