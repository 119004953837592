.col-md-2 {
  .comment-extend {
    width: calc(100vw * (2/12)); } }

.col-md-3 {
  .comment-extend {
    width: calc(100vw * (3/12)); } }

.comment-extend {
  position: fixed;
  right: 0;
  top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 50px);
  z-index: 2;

  .comment-extend_list {
    display: -webkit-box;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    order: 2;

    &::-webkit-scrollbar {
      width: 0.1875rem;
      background-color: transparent; }

    &::-webkit-scrollbar-thumb {
      background-color: #aeb4be;
      border-radius: 0.09375rem; }

    &[data-empty="true"] {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center; }

    & > div {
      width: 100%; }

    .media .media-image > img {
      max-width: 70px; }

    #flatListForExtend {
      .column > .row {
        margin-left: 0 !important;
        margin-right: 0 !important; } } }

  .comment-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
    color: #666666;

    div {
      font-size: 14px;
      color: #666666;
      margin-top: 20px; } }

  .close-comment-extend {
    width: 21px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      background-color: #EAEDF1; } }

  .dropdown-memo-item {
    width: 21px;
    text-align: center;
    border-radius: 50%;
    padding: 0;
    &:hover {
      background-color: #EAEDF1; } }


  .block-input {
    border-bottom: 1px solid #e2e2e2;
    order: 1;
    padding: 15px;

    .block-input__header, .actions {
      display: flex;
      justify-content: space-between;
      align-items: center; }

    strong {
      font-size: 16px;
      color: #232323; }

    button {
      border-radius: 15px; }

    .block-input__body textarea {
      resize: none; } } }

body.modal-open .comment-extend {
  margin-right: 17px; }

.app-body[data-theme="dark"] {
  .comment-extend {
    .block-input {
      .block-input__body textarea {
        color: #fff;
        background-color: #212F3D; } }
    .comment-empty {
      background: unset; } } }
