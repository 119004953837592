
@import url("https://fonts.googleapis.com/earlyaccess/notosansjapanese.css");
$desktop : "(min-width: 1200px)";
$max-567 : "(max-width: 567px)";
$max-667 : "(max-width: 667px)";
$min-768 : "(min-width: 768px)";
$XS:     "480px";
$SM:     "768px";
$MD:    "1024px";
$XL:   "1170px";
$XXL:   "1900px";

body, h1, h2, h3, h4, h5, p, span {
  font-family: 'Noto Sans Japanese', sans-serif; }

body {
  font-size: 14px; }

a {
  color: #3386cc; }

p {
  margin-bottom: 0!important; }

.fs-14 {
  font-size: 14px; }

.link-regis {
  padding-left: 0px!important;
  padding-right: 0px!important; }

header {
  background: #3945bd;
  color: #fff;
  padding: 10px;
  border-bottom: 1px solid #cccccc;
  height: 53px;
  line-height: 30px;
  font-size: 14px;
  a {
    color: #fff;
    text-decoration: none; } }

.header__logo {
  width: 142px;
  height: 33px;
  margin: 0;
  padding: 0;
  font-size: 0;
  float: left; }

.header__user {
  float: right;
  max-width: 52%; }

.header__user a {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.header__user a:hover {
  color: #fff;
  text-decoration: none; }

.header__user img {
  width: 30px;
  height: 30px;
  margin: -4px 7px 0 0;
  border-radius: 50%; }

.header__user .dropdown-menu a {
  color: #666666; }

.header__user .dropdown-menu a:hover {
  color: #3386cc; }

header li {
  list-style: none; }
.header__user.nav-item a {
  color: #fff !important;
  padding-bottom: 0;
  padding-top: 0; }

.header__user.nav-item .log-in-to-watch-div {
  right: 100%;
  top: 100%;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 0.25rem 0 0.25rem 0.25rem;
  transform: translateX(10px);

  ::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 100%;
    margin-left: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #fff #fff transparent; }

  a {
    color: #000 !important;
    padding: 0.25rem 1.5rem !important; } }

.header__user.nav-item a.dropdown-item {
  color: black !important;
  padding-left: 24px;
  padding-right: 24px; }
//@media #{$desktop}
//  .container-fluid
//    width: 85% !important

@media #{$max-567} {
  .course-video {
    position: sticky;
    top: 50px;
    z-index: 500;
    background: #FFFFFF; }

  .container-fluid > .row {
    margin-top: 3rem!important; } }

.container-fluid > .row {
  margin-top: 1rem!important; }

.alert-AuthC {
  color: #0c5460;
  background-color: rgba(52,58,64,0.8); }

/*Acodion-btn*/
.moreImgLink a.collapsed > span.more,
.moreImgLink a > span.simple {
  display: block; }

.moreImgLink a > span.more,
.moreImgLink a.collapsed > span.simple {
  display: none; }

.media > img {
  width: 100%;
  max-width: 100px; }

#MemoList > li > .dropdown {
  position: absolute;
  right: .75rem;
  top: .75rem; }

.dropdown-item {
  font-size: 14px; }

.nav-tabs .nav-item {
  margin-bottom: 0 !important;
  border: 0 !important; }

.nav-tabs .nav-link {
  border-top: 0;
  border-left: 0;
  border-right: 0; }

.nav-item a {
  color: #232323;
  padding-left: 15px;
  padding-right: 15px; }

.nav-tabs {
  .nav-link {
    &.active {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 3px solid #232323; }
    &:hover {
      border-bottom: 3px solid #e2e2e2; } } }

@media #{$min-768} {
  .list-group-flush {
    height: 500px;
    overflow: auto; } }

// @media only screen and (min-width: $XS)
//   #videoMainThumb
//     width: 100%

// @media only screen and (min-width: $SM)
//   #videoMainThumb
//     width: 100%
//     height: 37%

// @media only screen and (min-width: $MD)
//   #videoMainThumb
//     width: 100%
//     height: 45.2%

// @media only screen and (min-width: $XL)
//   #videoMainThumb
//     width: 100%
//     height: 56.5%

// @media only screen and (min-width: $XXL)
//   #videoMainThumb
//     width: 100%
//     height: 59%

#TimeTable > li {
  cursor: pointer; }

@media #{$max-667} {
  .list-group-flush {
    overflow: no-display;
    box-sizing: border-box; }

  .p-rl0 {
    padding-left: 0;
    padding-right: 0; }

  .p-rl15 {
    padding-left: 15px;
    padding-right: 15px; }

  .container-fluid > .row {
    margin-top: 0px!important; }

  .container-fluid > .row,
  .col-md-8 > .row {
    margin-left: 0;
    margin-right: 0; } }

footer {
  font-size: 12px;
  color: #666666;
  margin-top: 100px;
  border-top: 1px solid #cccccc;

  .footer__nav {
    display: flex;
    flex-direction: row; }

  .footer__nav__item {
    padding: 15px 10px;
    position: relative;

    &:nth-child(-n+2):after {
      width: 1px;
      height: 1.5em;
      border-left: 1px solid #666666;
      content: "";
      position: absolute;
      right: 0; }
    &:last-child {
      margin-left: auto; }
    a {
      color: #666; }

    .scorm-text-footer:after {
      width: 1px;
      height: 1.5em;
      border-left: 1px solid #666666;
      content: "";
      position: absolute;
      left: 0; } }

  .footer__nav__item > a:hover {
    color: #0056b3;
    text-decoration: underline; } }

@media #{$max-667} {
  footer {
    .footer__nav {
      flex-direction: column !important; }
    .footer__nav__item {
      padding-bottom: 0;
      &:nth-child(-n+2):after {
        display: none; }
      &:last-child {
        margin-left: 0 !important;
        padding-bottom: 10px; } } } }

#player_console {
  background-color: #2d2d2d;
  line-height: 25px;
  padding: 5px 15px;
  width: 100%;
  height: auto;
  border-top: 1px solid #000; }

#player_console #player_seek {
  float: left; }

#player_console #player_seek li {
  float: left;
  padding-top: 6px;
  margin-right: 11px;
  cursor: pointer;
  list-style: none; }

#player_console #player_seek a {
  display: block;
  color: #fff;
  background: url("../assets/image/sprite_player_console.png") no-repeat;
  text-decoration: none; }

#player_console #player_seek .seeks-prev-more a {
  background-position: 0 0;
  width: 14px;
  height: 11px; }

#player_console #player_seek .seeks-prev-more:hover a {
  background-position: 0 -12px;
  width: 14px;
  height: 11px; }

#player_console #player_seek .seeks-prev a {
  background-position: 0 -24px;
  width: 9px;
  height: 11px; }

#player_console #player_seek .seeks-prev:hover a {
  background-position: 0 -36px;
  width: 9px;
  height: 11px; }

#player_console #player_seek .seeks-skip {
  color: #fff;
  background: none;
  font-size: 0.9em;
  text-indent: 0;
  padding-top: 0;
  cursor: default; }

@media #{$max-667} {
  #player_console #player_seek .seeks-skip {
    display: none; } }

#player_console #player_seek .seeks-next a {
  background-position: 0 -48px;
  width: 9px;
  height: 11px; }

#player_console #player_seek .seeks-next:hover a {
  background-position: 0 -60px;
  width: 9px;
  height: 11px; }

#player_console #player_seek .seeks-next-more a {
  background-position: 0 -72px;
  width: 14px;
  height: 11px; }

#player_console #player_seek .seeks-next-more:hover a {
  background-position: 0 -84px;
  width: 14px;
  height: 11px; }

#player_speed {
  visibility: hidden; }

#player_console #player_speed p {
  float: left;
  color: #fff;
  font-size: 0.9em; }

#player_console #player_option {
  float: right; }

#player_console #player_option ul {
  position: relative; }

#player_console #player_option li {
  float: left;
  position: relative;
  left: 0;
  line-height: 25px;
  padding-top: 4px;
  height: 25px;
  margin-left: 11px;
  cursor: pointer;
  list-style: none; }

#player_console #player_option a {
  display: block;
  background: url("../assets/image/sprite_player_console.png") no-repeat;
  text-decoration: none; }

#player_console #player_option [data-func="largevideo"] a {
  background-position: 0 -114px;
  width: 43px;
  height: 15px; }

#player_console #player_option [data-func="largevideo"]:hover a {
  background-position: 0 -130px;
  width: 43px;
  height: 15px; }

#player_console #player_option [data-func="largevideo"].selected {
  padding-top: 6px; }

#player_console #player_option [data-func="largevideo"].selected a {
  background-position: 0 -146px;
  width: 40px;
  height: 12px; }

#player_console #player_option [data-func="largevideo"].selected:hover a {
  background-position: 0 -159px;
  width: 40px;
  height: 12px; }

#player_console #player_option [data-func="fullvideo"] a {
  background-position: 0 -172px;
  width: 15px;
  height: 15px; }

#player_console #player_option [data-func="fullvideo"]:hover a {
  background-position: 0 -188px;
  width: 15px;
  height: 15px; }

.ui-slider-horizontal {
  height: 5px; }

.ui-slider {
  float: left;
  position: relative;
  background-color: #555;
  text-align: left;
  width: 100px;
  margin: 9px 5px 0; }

.ui-slider-horizontal .ui-slider-range-min {
  left: 0; }

.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%; }

.ui-slider .ui-slider-range {
  position: absolute;
  background-color: #888;
  z-index: 1;
  font-size: .7em;
  display: block;
  border: 0;
  background-position: 0 0; }

.ui-slider-horizontal .ui-slider-handle {
  top: -.3em;
  margin-left: -.6em; }

.ui-slider .ui-slider-handle {
  position: absolute;
  background: url("../assets/image/sprite_player_console.png") no-repeat 0 -96px;
  z-index: 2;
  width: 16px;
  height: 17px;
  outline: none; }

.slider-horizontal {
  float: left;
  width: 100px; }
.slider-horizontal .rangeslider {
  margin: 8px;
  border-radius: 0;
  background-color: #555;
  height: 7px; }
.slider-horizontal .rangeslider__fill {
  background-color: #888;
  border-radius: 0; }
.slider-horizontal .rangeslider-horizontal {
  margin: 8px; }
.slider-horizontal .rangeslider__handle {
  text-decoration: none;
  border-radius: 0;
  background: url("../assets/image/sprite_player_console.png") no-repeat -1px -99px;
  width: 17px;
  height: 16px;
  border: none;
  box-shadow: none; }
.rangeslider-horizontal .rangeslider__handle:after {
  content: none; }

.filterBlur2 {
  filter: blur(3px); }
.wapper-img {
  background: #000;
  text-align: center !important;
  overflow: hidden !important;
  display: block !important;
  img {
    vertical-align: middle; }

  #videoMainThumb {
    aspect-ratio: 16 / 9;
    width: 100%;
    object-fit: contain; } }

.video-js {
  width: 100%;
  height: 100%;
  button:focus {
    outline: none; }
  .vjs-menu-button-popup {
    .vjs-menu {
       .vjs-menu-content {
        width: 65%;
        .vjs-menu-item {
         outline: none; } } }
    .vjs-selected,
    .vjs-selected:focus,
    .vjs-selected:hover,
    .js-focus-visible .vjs-menu li.vjs-selected:hover {
      background-color: #fff;
      color: #2B333F; } }
  .vjs-subs-caps-button {
    .vjs-menu-content {
      background-color: #2B333F;
      background-color: rgba(43, 51, 63, 0.7);
      position: absolute;
      width: 230px;
      bottom: 1.5em;
      right: 20px;
      max-height: 15em; }
    .vjs-texttrack-settings {
      display: none; }
    .vjs-menu-item-text {
      text-transform: capitalize; } }
  .vjs-big-play-button {
    height: 3.125rem;
    width: 3.125rem;
    border-radius: 50%;
    margin-left: -1em; }
  .vjs-subs-caps-button {
    .vjs-menu-content {
      width: 230px !important; } } }
.vjs-playing {
  .vjs-big-play-button {
    display: none; } }
.vjs-paused {
  .vjs-big-play-button {
    display: block; } }
.vjs-modal-dialog {
  background-color: rgba(43, 51, 63, 0.75) !important; }
.tab {
  .nav-item {
    cursor: pointer; } }
.progress {
  td {
    cursor: pointer; } }
@media screen and (min-width: $SM) and (max-width: $MD) {
   .video-slider-swaped {
      .video-js {
        width: 100%;
        height: 100%;
        .vjs-menu-button-popup .vjs-menu {
          margin-bottom: 9.5px; }
        .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
          width: 45%;
          font-size: 7px; }
        .vjs-subs-caps-button .vjs-menu-content {
          width: 160px !important;
          max-height: 13em; }
        .vjs-control-bar {
          height: 2em; }
        .vjs-control {
          width: 2em; }
        .vjs-volume-bar {
          margin: 0.9em 0.45em; } }
      .vjs-button > .vjs-icon-placeholder:before {
         font-size: 1.4em;
         line-height: 1.5; }
      .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-hover,
      .vjs-volume-panel.vjs-volume-panel-horizontal:active,
      .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-slider-active {
         width: 7em; }
      .vjs-remaining-time {
         display: none; }
      .vjs-volume-panel.vjs-hover .vjs-volume-control.vjs-volume-horizontal,.video-js .vjs-volume-panel.vjs-hover .vjs-mute-control ~ .vjs-volume-control.vjs-volume-horizontal {
         width: 5em;
         height: 2em; } } }

.show-large-video {
  .container-fluid > .row {
    margin-top: 0 !important; }

  .col-md-12.card-img {
    padding-left: 0;
    padding-right: 0; }

  .player {
    .player-video {
      height: calc((9 / 16) * 100vw);
      max-height: calc(100vh - 50px);
      min-height: 480px;
      position: relative;
      z-index: 2; }

    .embed-responsive.embed-responsive-16by9 {
      height: calc(100% - 36px); } }

  .wapper-img {
    height: calc((9 / 16) * 100vw);
    max-height: calc(100vh - 50px);
    min-height: 480px; }

  .learning-container {
    margin: 1rem 0; } }

@media only screen and (min-width: 1400px) {
  .show-large-video {
    .player {
      .player-video {
        max-height: calc(100vh - 140px) !important; } }

    .wapper-img {
      max-height: calc(100vh - 140px) !important; } } }

.video-js .vjs-text-track-display > div > div {
  inset: auto 0 0 !important; }
