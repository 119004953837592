.related-content-div {
  width: 100%;
  height: 100%;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;

  .related-content {
    max-width: 500px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    & > p {
      color: #fff;
      margin-bottom: 10px !important; }

    .content-related-thumbnail {
      position: relative;
      border: 1px solid #4c4c4c;
      flex: 1; }

    .content-related-title {
      flex: 1;

      .product-title {
        color: #fff; } }

    .content-related {
      display: flex;
      flex-wrap: wrap;
      column-gap: 10px; }

    img, figure {
      max-width: 100%;
      height: auto;
      vertical-align: middle; }

    .related-content-button {
      margin-top: 10px;
      gap: 10px;

      button {
        flex: 1; }

      .btn-cancel {
        background-color: #1A1A1A;
        border-color: #1A1A1A; }

      .btn-watching-now {
        background-color: #4D4D4D;
        border-color: #4D4D4D; } } } }

@media (max-width: 480px) {
  .related-content-div .related-content {
    width: 80%; } }

.video-slider-swaped .related-content-div .related-content {
  width: 80%; }

@media (min-width: 768px) and (max-width: 1023px) {
  .video-slider-swaped {

    .related-content-div {

      .theme-text {
        -webkit-line-clamp: 1; }

      .related-content {
        width: 90%;

        .related-content-button button {
          font-size: 10px; } }

      .content-related-title .product-title {
        font-size: 10px; }

      .content-related-thumbnail {
        flex: none;
        width: 60px;

        .program-duration {
          font-size: 8px;
          width: 30px; } }

      > p {
        font-size: 10px; } } } }

.vjs-control-bar .vjs-thumbnail {
  position: absolute;
  width: 158px;
  height: 90px;
  top: -100px;
  background-color: #000;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain; } }

#player-video:-webkit-full-screen {
  #player_console {
    display: none; }
  .embed-responsive.embed-responsive-16by9 {
    height: 100%; }
  .vjs-fullscreen-control .vjs-icon-placeholder:before, .vjs-icon-fullscreen-exit:before {
    content: "\f109"; } }

#player-video:fullscreen {
  #player_console {
    display: none; }
  .embed-responsive.embed-responsive-16by9 {
    height: 100%; }
  .vjs-fullscreen-control .vjs-icon-placeholder:before, .vjs-icon-fullscreen-exit:before {
    content: "\f109"; } }

.authn-character {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 100;
  background-color: rgba(35, 35, 35, 0.8);

  .authn-choice-list {
    padding: 15px 10px;
    gap: 10px; }

  .btn-authentication {
    width: 25%;
    min-width: 50px;
    height: 50px;
    box-sizing: border-box;
    text-align: center;
    color: #3386cc;
    background-color: #ffffff;
    border-radius: 30px;
    font-size: 24px;
    font-weight: bold;
    border: none;
    box-shadow: none;
    &:hover, &:focus, &:active {
      color: #ffffff;
      background-color: #3386cc; } }
  .text-acblack {
    color: #fff;
    display: block;
    width: 100%;
    text-align: center;
    padding: 20px 0;
    font-size: 20px;
    position: relative;
    .please-press {
      margin-left: 10px;
      transform: translateY(2px); }

    &::before {
      content: "";
      position: absolute;
      width: calc(100% - 20px);
      height: 1px;
      background-color: #999999;
      bottom: 0;
      left: 10px; } }

  .text-acblack .btn-authentication {
    width: 130px;

    &:hover, &:focus, &:active {
      color: #3386cc;
      background-color: #ffffff;
      box-shadow: none;
      cursor: unset; } } }
