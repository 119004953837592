body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500,700&display=swap");

@font-face {
  font-family: 'cartzilla-icons';
  src: url("fonts/cartzilla-icons.ttf?v4xwry") format("truetype"), url("fonts/cartzilla-icons.woff?v4xwry") format("woff"), url("fonts/cartzilla-icons.svg?v4xwry#cartzilla-icons") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="czi-"], [class*=" czi-"] {
  display: inline-block;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cartzilla-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1; }

.czi-home::before {
  content: "\e946"; }

.czi-arrow-right::before {
  content: "\e90a"; }
