.table-subtitle {
  border-bottom: 1px solid #dae1e7;

  th {
    text-align: center;
    background-color: #f3f5f9;
    color: black;
    height: 50px; }

  tr {
    cursor: pointer;

    &:hover {
      background-color: #efefef; } } }

.app-body[data-theme="dark"] {
  .table-subtitle {
    tr {
      color: #ffffff;
      &:hover {
        background-color: #2d4053; } }

    td, th {
      border: 1px solid rgba(0,0,0,.125); }

    th {
     color: #ffffff;
     background-color: #222; } } }
