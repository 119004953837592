.blue-play-button {
   background-color: #3386cc !important; }

.favorite-slide {
   position: absolute;
   right: 5px;
   bottom: 45px;
   height: 49px;
   width: 33px;
   z-index: 500;

   .favorite-num {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-tap-highlight-color: transparent;
      border-radius: 100px;
      display: inline-flex;
      height: 19px;
      line-height: 1;
      font-weight: 500;
      font-size: .7125rem;
      min-width: 19px;
      -webkit-user-select: none;
      background-color: transparent;
      cursor: pointer;

      span {
         justify-content: center;
         display: inline-flex;
         color: white;
         width: 100%;
         padding: 0;
         height: 100%;
         align-items: center;
         line-height: 1; } } }



.bi-bookmark-fill {
   cursor: pointer;
   font-size: 33px;
   -webkit-text-stroke-width: 0.3px;
   -webkit-text-stroke-color: #000000;

   &.not-favorite-icon {
      -webkit-text-fill-color: #808080c2; }

   &.favorite-icon {
      -webkit-text-fill-color: #FF8C00; } }
.slide-image {
   margin-left: auto;
   margin-right: auto;
   max-width: 100%;
   height: auto;
   vertical-align: middle; }
.slide-image-wrapper {
   background: black; }

.slide-free.carousel.slide {
   padding-bottom: 30px; }
